import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      sofieImage: file(relativePath: { eq: "sofie_profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      saraImage: file(relativePath: { eq: "sara_profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linneaImage: file(relativePath: { eq: "linnea_profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="bg-white antialised">
        <div className="px-4 pt-12 pb-16 bg-white sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto">
          <h1 className="mb-4 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Frisörer
          </h1>
          <div className="flex flex-col md:flex-row">
            <div className="w-full">
              <Img
                className="object-cover w-full lg:h-full"
                fluid={data.saraImage.childImageSharp.fluid}
                alt=""
              />
              <div className="mt-2">
                <p className="text-xl leading-7">Sara Bergius</p>
                <p className="text-base">Frisörmästare</p>
              </div>
            </div>
            <div className="w-full mt-4 md:ml-4 md:mt-0">
              <Img
                className="object-cover w-full lg:h-full"
                fluid={data.sofieImage.childImageSharp.fluid}
                alt=""
              />
              <div className="mt-2">
                <p className="text-xl leading-7">Sofie Måchtens</p>
                <p className="text-base">Frisörmästare</p>
              </div>
            </div>
            <div className="w-full mt-4 md:ml-4 md:mt-0">
              <Img
                className="object-cover w-full lg:h-full"
                fluid={data.linneaImage.childImageSharp.fluid}
                alt=""
              />
              <div className="mt-2">
                <p className="text-xl leading-7">Linnea Jonsson</p>
                <p className="text-base">Frisör</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
